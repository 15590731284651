@import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;500;700&family=Roboto:wght@400;700&family=Work+Sans:ital,wght@0,300;0,700;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@400;700&family=Work+Sans:ital,wght@0,300;0,700;1,300&display=swap');
/* Common Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style-type: none;
    outline: none;
    font-family: 'Jura', sans-serif;
}
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Button Section */
.main-btn {
    position: absolute;
    bottom: 3rem;
    width: 13rem;
    height: 13rem;
    background-color: #9b0e0e;
    border-radius: 50%;
    border: .2rem dotted #fff;
    padding: .1rem;
    overflow: hidden;
}
.main-btn-content {
    width: 100%;
    height: 100%;
    border: .2rem dotted #e7be08;
    border-radius: 50%;
    transform: rotate(40deg);
    transition: transform .2s,border .2s;
}
.main-btn:hover .main-btn-content{
    transform: rotate(60deg);
    border: .2rem dotted #9b0e0e;
    transition: transform .2s .5s,border .2s;
}

.main-btn-line {
    position: absolute;
    top: 4rem;
    width: 3rem;
    height: .5rem;
    border: .1rem solid #fff;
}
.main-btn-line:nth-child(1){
    transform: rotateZ(-45deg) translateX(-0.65rem);
    transform-origin: left center;
    border-right: none;
}
.main-btn-line:nth-child(2){
    transform: rotateZ(45deg) translateX(0.65rem);
    transform-origin: right center;
    border-left: none;
}
.main-btn-line:nth-child(3){
    transform: rotateZ(90deg) translateX(.5rem);
    border-left: none;
}
.main-btn span {
    position: absolute;
    bottom: 3rem;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
}
.ripple {
    position: absolute;
    width: 0;
    height: 0;
    background-color: #e7be08;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: rippleAnim .5s forwards;
}

@keyframes rippleAnim {
    0%{
        width: 0;
        height: 0;
    }
    100% {
        width: 210%;
        height: 210%;
    }
}

/* Section Heading */
.section-heading {
    font-size: 2rem;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 10rem;
    transform: translateY(-50%);
    width: 0;
    word-break: break-all;
    line-height: 4;
}
/* End of Section Heading */

/* End of Button Section */
/* End of Common Styles */

/* Mouse Circle */
.mouse-circle,
.mouse-dot {
    position: fixed;
    z-index: 300;
    transform: translate(-50%,-50%);
    opacity: 0;
    pointer-events: none;
}

.mouse-circle {
    height: 6rem;
    border: 0.1rem solid #c7a622;
    animation: mouseCircleAnim 10s infinite linear;
}
.mouse-dot {
    animation: mouseDotAnim 10s infinite linear;
}
@keyframes mouseDotAnim {
   0%{
       width: .5rem;
       height: .5rem;
   }
   55%{
    width: 1.5rem;
    height: 1.5rem;
   }
   100%{
    width: .5rem;
    height: .5rem;
   }

}
@keyframes mouseCircleAnim {
    0% {
        width: 6rem;
        height: 6rem;
    }
    25% {
        width: 8rem;
        height: 8rem;
    }
    35% {
        width: 4rem;
        height: 4rem;
    }
    70% {
        width: 8rem;
        height: 8rem;
    }
    100% {
        width: 6rem;
        height: 6rem;
    }
}
/* End of Mouse Circle */

/* Page BG */
.page-bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-color: #11111ade;
}
.containeraz{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-color: #11111ade;

}
/* End of Page BG */

/* Progress Bar */
.progress-bar{
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    width: 8rem;
    height: 8rem;
    background-color: #fff;
    border-radius: 50%;
    z-index: 200;
    overflow: hidden;
    transform: translate(-50%,-50%);
}




.progress-bar-circle{
    width: 7.8rem;
    height: 7.8rem;
    background-color: #000;
    border-radius: 50%;
    color: #fff;
    font-size: 4rem;
    z-index: 200;
    transition: transform .5s;
    pointer-events: none;
}
/* End of Progress Bar */

/* Navigation */
/* Menu Icon */
.menu-icon{
    position: fixed;
    top: 3.5rem;
    right: 0;
    width: 12rem;
    height: 7rem;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
    z-index: 100;
    cursor: pointer;
    transform: translate(-50%,-50%);
}

.menu-icon-line{
    width: 4rem;
    height: .1rem;
    background-color: #fff;
    margin: .5rem 0;
    box-shadow: 0 .3rem .5rem #000;
}

.show-menu-icon{
    opacity: 1;
    visibility: visible;
}
/* End of Menu Icon */
/* Navbar */
.navbar{
    position: fixed;
    top: 2.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    padding-right: 3rem;
    transition: opacity .5s, visibility .5s, transform .5s;
}

.navbar-link{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    margin: 0 2.5rem;
    text-shadow: 0 .3rem .5rem #000;
}

.hide-navbar{
    opacity: 0;
    visibility: hidden;
    transform: translateX(10rem);
}
/* End of Navbar */
/* End of Navigation */

/* Section 1 */
.section-1 {
    position: relative;
    width: 100%;
    height: 100vh;
    
}
/* Logo */
.logo{
    position: absolute;
    top: 2rem;
    left: 5rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #fff;
    width: 25vh;
}

.logo img{
    width: 100%;
}

/* End of Logo */

/* End of Animated Circles */

/* Featured Text */
.featured-text {
    font-size: 2rem;
    color: #fff;
    /* text-transform: uppercase; */
    width: 0;
    word-wrap: break-word;
    line-height: 2;
    position: sticky;
    top: 0;
}
.featured-text-1 {
    margin:0 auto 0 10rem;
}
.featured-text-2 {
    margin:0 10rem 0 auto;
}
/* End of Featured Text */



/* End of Section 1 */
/* Section 2 */
.section-2 {
    position: relative;
    padding: 10rem 10rem 15rem 10rem;
}

/* About Me Text */
.about-me-text {
    width: 80%;
    margin: auto;
}

.about-me-text span{
    font-family: "Poppins", sans-serif;
    font-size: 12rem;
    font-weight: bold;
    color: #aaa;
    letter-spacing: .5rem;
    line-height: .9;
    text-shadow: 0 0 1rem #9b0e0e;
    position: relative;
    mix-blend-mode: color-dodge;
}
@keyframes aboutMeTextAnim {
    0%{
        color: #aaa;
        top: 0;
    }
    10%,
    90% {
        color: #fff;
        top: 2rem;
    }
    100%{
        color: #aaa;
        top: 0;
    }
}
/* End of About Me Text */

/* Section 2 Main Button */
.section-2 .main-btn {
    left: 50%;
    bottom: -5rem;
    transform: translateX(-50%);
}
/* End of Section 2 Main Button */
/* End of Section 2 */

/* Section 3 */
.section-3 {
    width: 100%;
    height: 100%;
    padding: 10rem 0;
    margin-top: 10rem;
    position: relative;
}
/* Projects */
.projects{
    flex-wrap: wrap;
    padding-bottom: 15rem;
}
.project{
    width: 40rem;
    height: 45rem;
    overflow: hidden;
    margin: 1rem;
    background-color: #000;
    display: flex;
    justify-content: center;
    position: relative;
    border: .2rem dotted #9b0e0e;
    border-radius: 0.5rem;
    perspective: 20rem;
}
.project img {
    width: 90%;
    object-fit: cover;
    position: absolute;
    top: 2rem;
    pointer-events: none;
    transition: top 4s, opacity .2s ;
    opacity: .5;
}

.project:nth-child(even):hover img{
    animation: evenImgAnim 4s 0.2s;
    opacity: 1;
}

@keyframes evenImgAnim {
    0%{
        transform: rotateY(0);
    }
    25%{
        transform: rotateY(2deg) rotateX(-2deg);
    }
    50%{
        transform: rotateY(-2deg) rotateX(2deg);
    }
    100%{
        transform: rotateY(0);
    }
}
.project:nth-child(odd):hover img{
    animation: oddImgAnim 4s 0.2s;
    opacity: 1;
}

@keyframes oddImgAnim {
    0%{
        transform: rotateY(0);
    }
    25%{
        transform: rotateY(-2deg) rotateX(2deg);
    }
    50%{
        transform: rotateY(2deg) rotateX(-2deg);
    }
    100%{
        transform: rotateY(0);
    }
}

/* Big Project Image */
.project-img-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.9);
    overflow: hidden scroll;
    animation: imgWrapperAnim 1s forwards;
    scroll-behavior: smooth;
}
@keyframes imgWrapperAnim{
    0%{
        height: 0;
    }
    100%{
        height: 100%;
    }
}
.project-img{
    width: 100%;
    border-radius: .5rem;
    padding: 7rem 20rem;
    opacity: 0.9;
    display: block;
    animation: imgAnim 1s 1s both;
    transform-origin: top center;
}
@keyframes imgAnim{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}
/* End of Big Project Image */

/* Project Hide Button */
.project-hide-btn{
    position: fixed;
    top: 5rem;
    right: 5rem;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 2rem;
    letter-spacing: .1rem;
    cursor: pointer;
    z-index: 200;
    transform: scale(0);
    visibility: hidden;
}

.change.project-hide-btn{
    transform: scale(1);
    visibility: visible;
    transition: transform 0.5s;
}
/* End of Project Hide Button */

/* Projects Button */
.projects-btn{
    left: 50%;
    transform: translateX(-50%);
}
.projects-btn .main-btn-line{
    transition: all o.5s;
}
.change > .main-btn-line:nth-child(1){
    transform: rotateZ(45deg) translateX(-0.65rem);
}
.change > .main-btn-line:nth-child(2){
    transform: rotateZ(-45deg) translateX(0.65rem);
}
.change > .main-btn-line:nth-child(3){
    transform: rotateZ(90deg) translateX(-0.5rem);
    border-left: 0.1rem solid #fff;
    border-right: none;
}
/* End of Projects Button */
/* End of Projects */
/* End of Section 3 */

/* Section 4 */
.section-4{
    position: relative;
    width: 100%;

}

/* Services */
.services-wrapper{
    width: 100%;
    flex-direction: column;
}
.service{
    width: 70%;
    margin: 2rem 0;
    border-bottom: 0.5rem solid #9b0e0e;
}
.service-btn{
    width: 100%;
    position: relative;
    display: block;
    height: 15rem;
}
.service-btn span{
    font-family: "Poppins", sans-serif;
    font-size: 10rem;
    font-style: italic;
    font-weight: bold;
    color: #9b0e0e;
    position: absolute;
    right: 0;
    transition: right 0.5s;
    pointer-events: none;
}
.service-text{
    font-size: 4rem;
    color: #777;
    letter-spacing: 1rem;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
}
.change.service-text{
    height: 15rem;
    visibility: visible;
    opacity: 1;
    transition: height .5s, opacity .5s .5s;
}
/* End of Services */
/* End of Section 4 */

/* Section 5 */
.section-5{
    position: relative;
    height: 100vh;
    flex-direction: column;
    margin-top: 10rem;
}

/* Form */
.form-heading{
    font-family: "Poppins", sans-serif;
    font-size: 6rem;
    font-weight: 400;
    color: #9b0e0e;
    letter-spacing: .3rem;
    margin-bottom: 3rem;
    transition: opacity .3s;
}
.contact-form{
    flex-direction: column;
}
.contact-form-input{
    width: 60rem;
    padding: .5rem;
    margin: 1rem 0;
    background-color: rgba(255,255,255,.1);
    border: .1rem solid #9b0e0e;
    font-size: 1.7rem;
    font-weight: bold;
    color: #aaa;
    letter-spacing: .1rem;
    height: 5rem;
}
.contact-form-textarea{
    height: 20rem;
    resize: none;
}
.form-submit-btn{
    width: 20rem;
    height: 5rem;
    background-color: #9b0e0e;
    border: none;
    align-self: flex-start;
    font-size: 1.8rem;
    color: #fff;
}
.form-submit-btn:hover{
    cursor: pointer;
}

/* Form Validation */
.message{
    font-size: 1.2rem;
    font-weight: 700;
    color: #f55e5e;
    text-transform: uppercase;
    letter-spacing: .1rem;
    margin: 0 auto 1rem 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}
.error.message {
    visibility: visible;
    opacity: 1;
}
/* End of Form Validation */

/* End of Form */

/* Slide Show */
.slideshow{
    display: flex;
    width: 50rem;
    margin-top: 10rem;
    padding: 0 2rem;
}
.slideshow-link{
    width: 20%;
    filter: brightness(.5);
    transition: filter 1.5s;
}

.slideshow-link:nth-child(3){
    transition: filter 0.5s;
}

.slideshow-link:nth-child(1),
.slideshow-link:nth-child(5){
    transition: width 0.5s, opacity 0.5s;
}

.slideshow-link img{
    width: 100%;
    padding: 0 1rem;
}

.faded-out{
    opacity: 0;
    width: 0;
}

.light{
    filter: brightness(1.5);
}
/* End of Slide Show */
/* End of Section 5 */

/* Responsive */
@media (max-width: 1500px){
  
    .about-me-text span{
        font-size: 11rem;

    }
    .section-4{
        margin-bottom: 10rem;
    }
    .service-btn span{
        font-size: 9rem;
    }
    .service-text{
        font-size: 4rem;
    }
    .w-1\/3 {
        width: 42%;
    }
}

@media (max-width: 1350px){
    .w-1\/3 {
        width: 48%;
    }
    .main-btn{
        width: 12.5rem;
        height: 12.5rem;
    }
    
    .progress-bar{
        right: 1rem;
        bottom: 0;
    }
    .about-me-text span{
        font-size: 10rem;
    }
}

@media (max-width: 1150px){
    html{
        font-size: 55%;
    }
    .mouse-circle,
    .mouse-dot{
        display: none;
    }
    .navbar-link{
        margin: 0 2rem;
    }
   
    .about-me-text span{
        font-size: 7.5rem;

    }
    .project-img {
        padding: 7rem 11rem;
    }
    .project-hide-btn{
        right: 2rem;
    }
    .service-btn{
        height: 12rem;
    }
    .service-btn span{
        font-size: 8rem;
    }
    .service-text{
        font-size: 3.5rem;
    }
    .slideshow{
        margin: 5rem 0;
    }
    .w-1\/3 {
        width: 43%;
    }
}

@media (max-width: 900px){
    .menu-icon{
        right: -4rem;
    }
    .navbar-link{
        font-size: 1.5rem;
        margin: 0 1rem;
    }
    .featured-text-1{
        margin: 0 auto 0 5rem;
    }
    .featured-text-2{
        margin: 0 5rem 0 auto;
    }
    .section-heading{
        left: 5rem;
    }
    .about-me-text span{
        font-size: 5.5rem;
        color: #ccc;
    }
    .service-btn{
        height: 9rem;
    }
    .service-btn span{
        font-size: 6rem;
    }
    .service-text {
        font-size: 3.2rem;
    }
}

@media (max-width: 700px){
    html{
        font-size: 50%;
    }
    .contact-form-input{
        width: 50rem;
    }
    .w-2\/5 {
        width: 100%;
    }
    .footer {
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        flex-direction: column;
    }
    .w-1\/3 {
        padding-top: 1.5rem;
        width: 100% !important;
    }
}
@media (max-width: 480px){
    html{
        font-size: 40%;
    }
    .menu-icon{
        right: -8rem;
    }
   

    .service-btn span{
        font-size: 4.5rem;
    }
    .service-text{
        font-size: 3rem;
        color: #bbb;
    }
    .contact-form-input{
        width: 40rem;
    }
    .slideshow{
        width: 45rem;
    }
    .slideshow-link img{
        padding: 0 1.1rem;
    }
}

@media (max-width: 400px){
    html{
        font-size: 35%;
    }

    .navbar{
        justify-content: center;
        padding: 0;
    }


}

/* End of Responsive */
/* styles.css */
.main-circle {
  width: 100%;
  height: auto;
  position: absolute;
  transition: width 0.2s, height 0.2s;
}

.section-1 {
text-align: center;
}

.main-circle {
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
}


.buttons {
    position: absolute;
    display: flex;
    gap: 50px;
    top: 14%;
    right: 25%;
  }
  .partnert{
    max-height: 3.5rem;
    min-height: 3rem;
  }
  .partnertmagma{
    max-height: 4.5rem;
    min-height: 4rem;
  }
  .footer{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
}

  .MagBtn {
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    background: transparent;
    border: 1px solid #333;
    background-color: #c7a622;
    cursor: pointer;
  }
  .custom-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #4caf50;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    bottom: 0;
  }
  
  .custom-button:hover {
    background-color: #45a049;
  }
  
  .play{
  display:block;
  margin:auto;
  background:url("../public/images/play-button-svgrepo-com.svg");
  background-size:100px 100px;
  background-position:50% 50%;
  background-repeat:no-repeat;
  width:130px;
  height:130px;
  border:7px dashed #30877a;
  border-radius:80%;
  box-sizing:border-box;
  transition:0.125s ease;
  }
  .pause{
    display:block;
    margin:auto;
    background:url("../public/images/music-player-pause-button-svgrepo-com.svg");
    background-size:100px 100px;
    background-position:50% 50%;
    background-repeat:no-repeat;
    width:125px;
    height:125px;
    border:7px dashed #873030;
    border-radius:80%;
    box-sizing:border-box;
    transition:0.125s ease;
    }

.play:hover{
    background-size:120px 120px;
  background-position:50% 50%;
  background-repeat:no-repeat;
  border:15px solid #30877a;
}
.pause:hover{
    background-size:120px 120px;
  background-position:50% 50%;
  background-repeat:no-repeat;
  border:15px solid #873030;
}
.w-1\/3 {
    width: 38%;
}

@media (max-width: 1540px){

    .buttons {
        top: 10%;
        right: 17%;
      }
      .w-1\/3 {
        width: 45.333333%;
    }
      
      .MagBtn {
        height: 7rem;
        width: 7rem;
      }

      
      .play{
  
      background-size:80px 80px;
     
      width:100px;
      height:100px;
    
      }
      .pause{
      
        background-size:80px 80px;
       
        width:95px;
        height:95px;
        
        }
    
    .play:hover{
        background-size:100px 100px;

    }
    .pause:hover{
        background-size:100px 100px;
    
    }
    
  }
  @media (max-width: 1100px){
    .w-1\/3 {
      width: 50%;
  }
}
  @media (max-width: 768px) {
    .main-circle {
        width: auto;
        margin-top: 0px !important;
        }        
        .buttons {
            top: 16%;
           right:10%;
        }
        .section-1 {
            position: relative;
            height: 95vh !important;
        }
}